<template>
<form @submit.prevent="">
    <div class="form-row">
        <div class="col-12	mb-3" v-if="event.label_id != -1">
            <div class="form-group">
                <div class="widget-select w-100 m-top calendar-tags widget-select-lg">
                    <v-select2 v-model="event.label_id" :clearable="false" :disabled="(event.editable == 1 ? false : true)" label="title" :reduce="(option) => option.id" :options="labels">
                    </v-select2>
                </div>
            </div>
        </div>
        <div class="col-12 ">
            <div class="form-group">
                <input type="text" id="event-title" placeholder="عنوان رویداد" class="widget-input input-no-bg input-lg input-set-border w-100 event-title" v-model="event.title" :disabled="(event.editable == 1 ? false : true)">
            </div>
        </div>
        <div class="col-6 event-date-modal">
            <div class="form-group">
                <label for="event-at">از تاریخ</label>
                <date-picker v-model="event.start" locale="fa,en" id="event-start" appendTo="body" inputClass="Datepicker widget-input input-no-bg input-lg input-set-border w-100" :clearable="true" @change="dateRangeChanged()" :disabled="(event.editable == 1 ? false : true)" type="datetime" compact-time auto-submit></date-picker>
            </div>
        </div>
        <div class="col-6 event-date-modal">
            <div class="form-group">
                <label for="event-end">تا تاریخ</label>
                <date-picker v-model="event.end" locale="fa,en" id="event-end" appendTo="body" inputClass="Datepicker widget-input input-no-bg input-lg input-set-border w-100" :clearable="true" @change="dateRangeChanged()" :disabled="(event.editable == 1 ? false : true)" type="datetime" compact-time auto-submit></date-picker>
            </div>
        </div>
    </div>
    <div class="form-row text-center mt-4" v-if="event.editable == 1">
        <div class="col-12">
            <a href="" @click.prevent="save()" :class="'m-left min-w150 tgju-btn tgju-btn-lg ' + [addEditLoading ? 'loading-active inline-flex' : ''] + [ editMode ? ' tgju-btn-success' : ' tgju-btn-primary' ]"><i class="uil uil-save"></i> {{ editMode ? 'ثبت ویرایش' : 'افزودن رویداد' }}</a>
            <a v-if="editMode" href="" @click.prevent="removeEvent()" class="m-right min-w150 tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-trash"></i> حذف رویداد</a>
        </div>
    </div>
</form>
</template>

<style lang="scss" scoped>
.cancel-edit-btn {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    color: #1d1c1c;
}
</style>

<script>
// این کامپوننت مودال افزودن و ویرایش ایونت به تقویم اقتصادی می باشد
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'addCalendarEventModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            event: {
                id: null,
                title: '',
                description: '',
                start: null,
                end: null,
                label_id: null,
            },
            labels: [],
            editMode: false,
            addEditLoading: false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true, deep: true });
    },
    methods: {
        // این متد یک سری داده های اولیه را به کامپوننت تزریق می کند
        loadData() {
            let labels = [{
                id: 0,
                title: 'تقویم عمومی',
                color: '',
            }];
            this.data.labels.forEach(label => {
                labels.push(label);
            });
            this.labels = labels;

            this.editMode = false;
            if (this.data.event) {
                this.event = this.data.event;
                this.editMode = true;
            } else {
                this.event.id = null;
                this.event.title = '';
                this.event.description = '';
                this.event.start = null;
                this.event.end = null;
                this.event.label_id = {
                    id: 0,
                    title: 'تقویم عمومی',
                    color: '',
                };
                this.event.editable = 1;
            }
        },
        // این متد برای کال کردن متد عملیات اصلی مورد استفاده میشود . 
        // ایا عملیات ویرایش است یا افزودن
        save() {
            if (this.editMode) {
                this.editEvent();
            } else {
                this.addEvent();
            }
        },
        // این متد برای افزودن یک رویداد جدید به تقویم اقتصادی استفاده میشود
        addEvent() {
            if (!this.event.title || !this.event.start) {
                return;
            }

            this.addEditLoading = true;

            let api_data = {
                title: this.event.title,
                description: this.event.description,
                start: this.event.start,
                end: this.event.end,
                label_id: this.event.label_id,
            };

            this.$helpers.makeRequest('POST', '/calendar/store-event', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.event.id = api_response.data.response.id;
                    this.$parent.emitData('addEvent', { event: this.$helpers.unbindObject(this.event) }, true);
                    this.$swal.fire({
                        icon: "success",
                        title: "رویداد با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد رویداد رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
                this.addEditLoading = false;
            });
        },
        // این متد برای ویرایش یک رویداد به تقویم اقتصادی استفاده میشود
        editEvent() {
            if (!this.event.title || !this.event.start) return;
            this.addEditLoading = true;

            let api_data = {
                title: this.event.title,
                description: this.event.description,
                start: this.event.start,
                end: this.event.end,
                label_id: this.event.label_id,
            };

            this.$helpers.makeRequest('POST', '/calendar/edit-event/' + this.event.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.$parent.emitData('editEvent', { event: this.$helpers.unbindObject(this.event) }, true);

                    this.$swal.fire({
                        icon: "success",
                        title: "رویداد با موفقیت ویرایش شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ویرایش رویداد رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
                this.addEditLoading = false;
            });
        },
        // این متد برای حذف یک رویداد از تقویم اقتصادی استفاده میشود
        removeEvent() {
            this.$parent.emitData('removeEvent', { event: this.$helpers.unbindObject(this.event) }, true);
        },
        // TODO: توجه فعلا یک فانکشن خالی گذاشتم تا ارور نده ولی باید توجیه شود
        dateRangeChanged() {},
    },
}
</script>
